import { Dialog, DialogContent, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ImgDialog({ open, onClose, prog }) {
  if (!prog) {
    return false;
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{ backgroundColor: "transparent" }}
    >
      <Box
        sx={{ display: "flex", padding: "0 20px 0 20px", alignItems: "center" }}
      >
        <Box sx={{ display: "flex", flex: 1 }}>
          {prog.Prestation.Projet.Societe.nom}
          {" - "}
          {prog.Prestation.Projet.ref}-{prog.Prestation.Projet.exercice}{" "}
          {prog.Prestation.Projet.nom}
          {" - "}
          <em>{prog.Prestation.TypePrestation.description}</em>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
        <Box sx={{ textAlign: "center", height: "100%" }}>
          {!prog.url && (
            <img
              src={prog.fullUrl}
              alt={prog.Prestation.Projet.nom}
              loading="lazy"
              style={{ maxWidth: "100%" }}
            />
          )}
          {prog.url && (
            <iframe src={prog.url} style={{ width: "100%", height: "100%" }} title={prog.Prestation.Projet.nom} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

/*
<DialogContentText>Test</DialogContentText>
*/
