import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./Layout";
import routes from "./routes";

export default function Catalogue() {
  return (
    <Router>
      <Layout routes={routes}>
        <Routes>
          {routes.map(route => {
            return (
              <Route
                exact
                path={route.path}
                element={route.component()}
                key={route.path}
              />
            );
          })}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}
