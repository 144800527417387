import Prestations from "./Prestations";
import { MapsHomeWorkOutlined } from "@mui/icons-material";

const routes = [
  {
    title: "Prestations",
    component: Prestations,
    path: "/",
    icon: MapsHomeWorkOutlined,
  },
];

export default routes;
