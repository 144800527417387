import React, { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

const authContext = createContext();

const Auth = {
  async signin(data) {
    return axios
      .post(process.env.REACT_APP_GPRC_BASE_URL + "/login", {
        login: data.login,
        pwd: data.password,
      })
      .then(resp => {
        return resp.data.token;
      });
  },
  async signout() {},
};

function initInterceptor(token) {
  Auth.interceptor = axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    let urlToken = new URLSearchParams(window.location.search).get("token");
    const token = urlToken ? urlToken : localStorage.getItem("catalogue-auth");
    if (token) {
      axios
        .post(
          process.env.REACT_APP_GPRC_BASE_URL + "/token/verify",
          { token },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(data => {
          if (!data.data.err) {
            initInterceptor(token);
            setUser(true);
            localStorage.setItem("catalogue-auth", token);
          }
        })
        .catch(err => Promise.resolve());
    }
  }, []);

  const signin = async data => {
    const token = await Auth.signin(data);
    localStorage.setItem("catalogue-auth", token);
    initInterceptor(token);
    setUser(true);
  };

  const signout = async () => {
    await Auth.signout();
    setUser(null);
    axios.interceptors.request.eject(Auth.interceptor);
    localStorage.removeItem("catalogue-auth");
  };

  return {
    user,
    signin,
    signout,
  };
}
