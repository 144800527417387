import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ProvideAuth, useAuth } from "./hooks/auth";

import Login from "./components/Login";
import Catalogue from "./components/Catalogue";
const theme = createTheme({});

function LoggedCatalogue() {
  const { user } = useAuth();
  return user ? <Catalogue /> : <Login />;
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAuth>
        <LoggedCatalogue />
      </ProvideAuth>
    </ThemeProvider>
  );
}
